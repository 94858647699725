<template>
  <div class="source-player">
    <div
      class="play-box"
      :style="{ height: boxHeight }"
      ref="playbox"
      v-if="current.type == 'mp4'"
    >
      <video-player
        class="video-player-box"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        customEventName="customstatechangedeventname"
      >
      </video-player>
    </div>
    <div
      class="play-box-audio"
      :style="{ height: boxHeight }"
      ref="playbox"
      v-else-if="current.type == 'mp3'"
    >
      <audio controls :src="current.url" controlsList="nodownload">
        您的浏览器不支持 audio 标签。
      </audio>
    </div>
    <div
      class="play-box"
      :style="{ height: boxHeight }"
      ref="playbox"
      v-else-if="current.type == 'pdf'"
      @dblclick="toggle"
    >
      <a-alert show-icon message="双击可全屏展示" type="info" closable />
      <br />
      <fullscreen ref="fullscreen" @change="fullscreenChange">
        <pdf ref="pdf" :src="current.url" @loaded="loadPdfHandler"> </pdf>
      </fullscreen>
    </div>
    <div
      class="play-box"
      :style="{ height: '90vmin' }"
      ref="playbox"
      v-else-if="current.type == 'pic'"
      @dblclick="toggle"
    >
      <img class="photo" :src="current.url" alt="" srcset="" />
    </div>
    <div
      class="play-box-audio"
      :style="{ height: boxHeight }"
      ref="playbox"
      v-else
    >
      <a-button
        type="primary"
        size="large"
        icon="download"
        @click="downloadFile"
        >下载打开</a-button
      >
    </div>
    <ul class="source-right">
      <li
        class="source-right-item"
        :class="[currentIndex == index ? 'source-right-item-active' : '']"
        v-for="(item, index) in list"
        :key="item.url"
        @click="sourceClick(index)"
      >
        <div class="pic-warp">
          <img class="pic" :src="item.icon" alt="" srcset="" />
        </div>
        <p class="filename">{{ item.name }}</p>
        <img class="icon" :src="item.typeicon" alt="" srcset="" />
      </li>
    </ul>
  </div>
</template>
<script>
import poster from "@/assets/poster.png";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import pdf from "vue-pdf";
import { fileType, getFileType, getSource } from "@/utils/util";
import fullscreen from "vue-fullscreen";
import Vue from "vue";
Vue.use(fullscreen);
const whiteTypeList = ["doc", "xls", "unknown"];
export default {
  components: {
    videoPlayer,
    pdf,
  },
  props: {
    ispreview: {
      type: Boolean,
      default: true,
    },
    sources: {
      type: Array,
      default: () => {
        return [];
      },
    },
    previewIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fullscreen: false,
      loadedPdf: false,
      currentIndex: 0,
      playerOptions: {
        // videojs options
        muted: false,
        language: "zh",
        height: "410",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: poster,
      },
      list: [],
      boxHeight: "410px;",
    };
  },
  created() {
    setTimeout(() => {}, 2000);
  },
  updated() {
    this.setPlayBoxHeight();
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    current() {
      let item = this.list[this.currentIndex];
      if (!item.ApxUrl) {
        return null;
      }
      let type = getFileType(item.ApxUrl);
      let params = {
        url: item.ApxUrl,
        icon: item.icon,
        type: item.type,
      };
      if (type == "pdf") {
        params.url = "/pdf/" + getSource(item.ApxUrl);
      }
      return params;
    },
  },
  watch: {
    ispreview: {
      handler(n, o) {
        if (
          this.playerOptions.sources.length > 0 &&
          typeof this.$refs.videoPlayer != undefined
        ) {
          try {
            this.player.pause();
          } catch (error) {}
        }
      },
      immediate: true,
      deep: true,
    },
    previewIndex: {
      handler(n, o) {
        this.currentIndex = n;
      },
      immediate: true,
      deep: true,
    },
    sources: {
      handler(n, oldName) {
        this.list = [];
        n.forEach((item) => {
          let type = getFileType(item.ApxUrl);
          item.type = type;
          item.name = item.ApxFileName || item.ApxFileAnthorName;
          item.typeicon = fileType(item.ApxUrl);
          if (type == "pdf") {
            item.url = "/pdf/" + getSource(item.ApxUrl);
            item.icon = poster;
          } else if (type == "mp4") {
            item.icon = poster;
          } else if (type == "mp3") {
            item.icon = poster;
          } else if (type == "pic") {
            item.icon =
              item.ApxUrl + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
          } else {
            item.icon = poster;
          }
          this.list.push(item);
        });

        this.playerOptions.poster = this.current.icon;
        this.loadedPdf = false;
        this.playerOptions.sources = [
          {
            type: "video/mp4",
            src: this.current.url,
          },
        ];
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.setPlayBoxHeight();
    window.onresize = () => {
      this.setPlayBoxHeight();
    };
  },
  methods: {
    setPlayBoxHeight() {
      if (this.$refs.playbox) {
        let width = window
          .getComputedStyle(this.$refs.playbox)
          .width.replace("px", "");
        this.boxHeight = (width * 9) / 16 + "px";
        this.playerOptions.height = (width * 9) / 16;
      }
    },
    getImgUrl(item) {
      return this.list[i].icon;
    },
    loadPdfHandler() {
      this.loadedPdf = !this.loadedPdf;
    },
    toggle() {
      this.$refs["fullscreen"].toggle(); // recommended
      // this.fullscreen = !this.fullscreen // deprecated
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
    sourceClick(index) {
      if (index == this.currentIndex) {
        return;
      }
      if (this.current.type == "mp4") {
        this.player.pause();
      }
      this.currentIndex = index;
      if (this.current.type == "mp4") {
        this.playerOptions.poster = this.current.icon;
        this.loadedPdf = false;
        this.playerOptions.sources = [
          {
            type: "video/mp4",
            src: this.current.url,
          },
        ];
      }
    },
    downloadFile() {
      window.open(this.current.url);
    },
  },
};
</script>
<style lang="less">
.source-player{
    padding-top: 24Px;
    position: relative;
    .play-box {
        width:calc(100% - 220Px);
        background: gold;
        height: 410Px;
        background: white;
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        img {
            height: 100%;
            display: block;
            display: block;
            margin:  0 auto;
        }
        .vjs-big-play-button {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
    }
    .play-box::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
    .play-box-audio {
        width:calc(100% - 220Px);
        height: 410Px;
        z-index: 900;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1Px solid #EDF0F5;
        audio {
            outline: none;
        }
        audio::-webkit-media-controls {
            overflow: hidden !important
        }
        audio::-webkit-media-controls-enclosure {
            width: calc(90% + 32px);
            margin-left: auto;
        }
    }
    .source-right {
        width: 200Px;
        position: absolute;
        right: 0;
        top: 24Px;
        padding: 0;
        bottom: 0Px;
        overflow-y: scroll;
        .source-right-item {
            cursor: pointer;
            width: 200Px;
            margin-bottom: 15Px;
            display: flex;
            justify-self: start;
            position: relative;
            .pic-warp {
                display: block;
                // width: 80Px;
                height: 45Px;
                // overflow: hidden;
                border: 1Px solid #f4f4f4;
                border-radius: 2Px;
                .pic {
                    display: block;
                    width: 80Px;
                    height: 45Px;
                    object-fit: cover;
                    filter: grayscale(100%);
                    -webkit-transition: -webkit-transform ease .25s;
                    transition: -webkit-transform ease .25s;
                    transition: transform ease .25s;
                    transition: transform ease .25s, -webkit-transform ease .25s;
                }
                .pic:hover {
                    -webkit-transform: scale(1.015);
                    -moz-transform: scale(1.15);
                    -ms-transform: scale(1.15);
                    -o-transform: scale(1.15);
                    transform: scale(1.15);
                }
            }
            
            .filename {
               font-size: 12Px;
               padding-left: 10Px;
               text-align: left;
            }
            .icon {
                position: absolute;
                z-index: 100;
                left: 1Px;
                top: 25Px;
                width: 20Px;
                height: 20Px;
            }
        }
        .source-right-item-active {
            .pic-warp { 
                .pic {
                    filter: grayscale(0%);
                }
            }
            .filename {
               color: @primary-color;
            }
        }
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
    }
    .source-right::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
}
/* For demo */
.ant-carousel  .slick-dots {
  height: auto;
}

.ant-carousel  .slick-slide img {
  display: block;
  margin: auto;
  max-width: 100%;
}

</style>
